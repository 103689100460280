@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin box-shadow($prop) {
  -webkit-box-shadow: $prop;
     -moz-box-shadow: $prop;
      -ms-box-shadow: $prop;
          box-shadow: $prop;
}

@mixin text-shadow($prop...) {
  -webkit-text-shadow: $prop;
     -moz-text-shadow: $prop;
      -ms-text-shadow: $prop;
          text-shadow: $prop;
}

@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
   @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
      $shadow: append($shadow, $i*1px $j*1px 1px $color, comma);      
    }
  }
  @return $shadow;
}

@mixin text-stroke($stroke, $color) {
  @include text-shadow( stroke($stroke, $color) );
}


@mixin letter-spacing($tracking){
	letter-spacing: ( $tracking / 1000 ) * 1em; 
}

// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }
  -moz-transition: $unfoldedTransitions;
  -o-transition: $unfoldedTransitions;
  -webkit-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .2s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

@mixin transform( $prop... ){
    -webkit-transform: $prop;
    -moz-transform: $prop;
    -o-transform: $prop;
    transform: $prop;
}

@mixin transform-origin($prop){
  -webkit-transform-origin: $prop;
  -moz-transform-origin: $prop;
  -o-transform-origin: $prop;
  transform-origin: $prop;
}

@mixin clearfix(){
	content: "";
	display: block;
	float: none;
	clear: both;
}

@mixin ul-col( $count ){
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;

}

@mixin grayscale($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
    -moz-filter: #{"grayscale(#{$value})"};
    filter:#{"grayscale(#{$value})"};
}
