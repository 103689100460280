$container-width: 1240px;
$container-padding: 0 20px;
$spacer: 16px;
$row-margin: 0;

$p:'PoppinsRegular';
$p-m:'PoppinsMedium';
$p-i:'PoppinsItalic';
$p-sb:'PoppinsSemiBold';
$p-b:'PoppinsBold';
$mainfont: $p-m;
$mainfont-bold: $p-sb;
 
$white: #FFFFFF;
$black: #000000;
$blue: #09357a;
$blue2: #1d407c;
$blue3: #000c24;
$blue4: #204887;
$blue5: #1a69a9;
$blue6: #0d2346;
$red: #c41130;
$red2: #ed1245;
$red3: #c90016;
$grey: #282c2b;
$grey2: #e8e8e8;
$grey3: #d4cfcf;
$border-gr: #828282;
$global-color: $grey;

$max-desk: 1230px;
$min-desk: 1160px;
$reviews-responsive: 1120px;
$max-tab: 1060px;
$min-tab: 920px;
$wp-mobile: 820px;
$wp-min-mobile: 782px;
$gf-min-mobile: 640px;
$wp-small: 600px;
$wp-verysmall: 480px;